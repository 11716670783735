/* You can add global styles to this file, and also import other style files */
@import './app/styles/mytheme';
@import './app/styles/material-theme';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
